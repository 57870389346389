import React, { Component } from 'react'

export default class footer extends Component {
    render() {
        return (
            <div>
                <footer className="footer-area">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <div className="single-footer-widget pd-bottom">

                                    <div className="interrio-info">
                                        <p><span>STL İnşaat;</span> Kuruluşumuzdan bugüne kadar kalite çizgisinden ödün vermeyerek uzman kadro ve ekibi ile kırım karot anahtar teslim projelerini eksiksiz olarak teslim etmektedir. Firma olarak önceliğimiz müşteri memnuniyeti kaliteli hizmet sunmaktır.</p>
                                    </div>

                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <div className="single-footer-widget pd-bottom">

                                    <ul className="footer-contact-info">
                                        <li>
                                            <div className="icon-holder">
                                                <span className="flaticon-building"></span>
                                            </div>
                                            <div className="text-holder">
                                                <p> Esentepe Mah. Köprülü Sok. No:3a Şişli/İstanbul</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon-holder">
                                                <span className="flaticon-technology"></span>
                                            </div>

                                            <div className="text-holder">
                                                <p>(212) 2810061</p>
                                            </div><div className="text-holder">
                                                <p>(552) 2241402</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon-holder">
                                                <span className="flaticon-e-mail-envelope"></span>
                                            </div>
                                            <div className="text-holder">
                                                <p>info@stlinsaat.com</p>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <div className="single-footer-widget pd-bottom">

                                    <ul className="footer-contact-info">

                                        <li>
                                            <div className="icon-holder time">
                                                <span className="flaticon-clock"></span>
                                            </div>
                                            <div className="text-holder">
                                                <p>7/24 Hizmet vermekteyiz<br /> Bize ulaşın.</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>


                        </div>
                    </div>
                </footer>

                <section className="footer-bottom-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                                <div className="copyright-text">
                                    <p>© 2021 All Rights Reserved by  <a href="/"> &nbsp; Stl İnşaat Gıda Tic. Ltd. Şti. </a></p>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                                <div className="footer-menu">
                                    <ul>
                                        <li><a href="/"><i className="fa fa-facebook" aria-hidden="true"></i>{ }</a></li>
                                        <li><a href="https://z-p15.www.instagram.com/stlinsaat/"><i className="fa fa-instagram" aria-hidden="true"></i>{ }</a></li>
                                        <li><a href="/"><i className="fa fa-youtube" aria-hidden="true"></i>{ }</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
