import React from 'react'

export default function nav(props) {
    console.log(props.id)
    return (

        <div>
            <section className="top-bar-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-7">
                            <div className="contact-info-left clearfix">
                                <ul>
                                    <li><span className="flaticon-building"></span>Esentepe Mah. Köprülü Sok. No:3a Şişli/İstanbul</li>
                                    <li><span className="flaticon-new-email-outline envelop"></span>info@stlinsaat.com</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5">
                            <div className="contact-info-right">
                                <div className="phnumber">
                                    <p><span className="flaticon-technology"></span>(212) 281 00 61  -  (552) 224 14 02</p>
                                </div>


                                <div className="top-social-links">
                                    <ul>

                                        <li><a href="https://z-p15.www.instagram.com/stlinsaat/"><i className="fa fa-instagram" aria-hidden="true"></i>{ }</a></li>
                                        <li><a href="/"><i className="fa fa-facebook" aria-hidden="true"></i>{ }</a></li>
                                        <li><a href="/"><i className="fa fa-youtube" aria-hidden="true"></i>{ }</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <header className="header-area stricky">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="outer-box clearfix">
                                <div className="logo">
                                    <a href="/">
                                        <img height="38" width="216" src="/images/logo/stllogom1.png" alt="." />
                                    </a>
                                </div>

                                <nav className="main-menu">
                                    <div className="navbar-header">
                                        <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                                            <span className="icon-bar"></span>
                                            <span className="icon-bar"></span>
                                            <span className="icon-bar"></span>
                                        </button>
                                    </div>
                                    <div className="navbar-collapse collapse clearfix">
                                        <ul className="navigation">
                                            <li className={` ${props.id === '/' ? 'current' : ' '}`}><a href="/">Ana Sayfa</a></li>
                                            <li className={`dropdown  ${props.id === '/projeler' ? 'current' : ' '}`}><a href="/projeler">Projeler</a>
                                                <ul>
                                                    <li><a href="/yikimsokum">Yıkım Söküm</a></li>
                                                    <li><a href="/karotdelme">Karot Delme</a></li>
                                                    <li><a href="/insaatisleri">İnşaat İşleri</a></li>
                                                    <li><a href="/restoranproje">Restoran</a></li>
                                                </ul>
                                            </li>
                                            <li className={` ${props.id === '/hakkimizda' ? 'current' : ' '}`}><a href="/hakkimizda">Hakkımızda</a>
                                            </li>
                                            <li className={` ${props.id === '/iletisim' ? 'current' : ' '}`}><a href="/iletisim">İLETİŞİM</a>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    )

}
