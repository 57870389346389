import React, { Component } from 'react'
import emailjs from 'emailjs-com';
import { init } from 'emailjs-com';
init("user_iuPGYftvgcOfGOIYy5YmZ");

export default class iletisim extends Component {

    sendEmail(e) {
        e.preventDefault();
        //gmail  emailjs.sendForm('service_0gefufk', `template_jenqp0b`, e.target, 'user_iuPGYftvgcOfGOIYy5YmZ')
        emailjs.sendForm('service_70xa4l8', `template_jenqp0b`, e.target, 'user_iuPGYftvgcOfGOIYy5YmZ')
            .then((result) => {
                this.setState({ mailresult: 1 })
                this.clearForm();
            }, (error) => {
                this.setState({ mailresult: 2 })
            });
    }

    clearForm = function () {
        let secBankFields = document.getElementsByTagName('input');
        for (var i = 0; i < secBankFields.length; ++i) {
            if (secBankFields[i].id === 'formid')
                secBankFields[i].value = '';
        }
        secBankFields = document.getElementsByTagName('textarea');
        for (var y = 0; i < secBankFields.length; ++y) {
            if (secBankFields[i].id === 'formid')
                secBankFields[i].value = '';
        }
    }



    state = {
        mailresult: 0
    }

    render() {

        return (
            <div>
                <section className="breadcrumb-area" style={{ backgroundImage: 'url(/images/breadcrumb/iletisimbanner.jpg)' }}>
                    <div className="container-fluid text-center">
                        <h1>{ }</h1>
                        <div className="breadcrumb-bottom">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="left pull-left">
                                            <ul>
                                                <li><a href="/">Ana Sayfa</a></li>
                                                <li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                                                <li>İletişim</li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="contact-v1-area">
                    <div className="container">
                        <div className="sec-title text-center">
                            <h2>BİZE Ulaşın</h2>
                            <p>Siz değerli müşterilerimize yapmış olduğunuz projeler ve  bu alandaki tecrübelerimizi aktarmaktan mutluluk duyacağız. 7/24 Hizmet vermekteyiz.</p>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="img-holder">
                                    <img src="/images/resources/iletisimresim.jpg" alt="." />
                                </div>
                                <div className="contact-info">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="single-item">
                                                <div className="icon-holder">
                                                    <span className="flaticon-building"></span>
                                                </div>
                                                <div className="text-holder">
                                                    <h5>Adres:</h5>
                                                    <p> Esentepe Mah. Köprülü Sok. No:3a Şişli/İstanbul</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="single-item">
                                                <div className="icon-holder">
                                                    <span className="flaticon-technology"></span>
                                                </div>
                                                <div className="text-holder">
                                                    <h5>Tel:</h5>
                                                    <p>(212) 2810061<br />(552) 2241402</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="single-item">
                                                <div className="icon-holder">
                                                    <span className="flaticon-new-email-outline"></span>
                                                </div>
                                                <div className="text-holder">
                                                    <h5>Email:</h5>
                                                    <p>info@stlinsaat.com</p>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="contact-form-v1">
                                    <form action="" onSubmit={(e) => this.sendEmail(e)} id="contact-form">
                                        <input type="text" id="formid" name="user_name" placeholder="Ad Soyad*" />
                                        <input type="text" id="formid" name="user_email" placeholder="Email*" />
                                        <input type="text" id="formid" name="user_contact" placeholder="Tel" />
                                        <textarea name="message" id="formid" placeholder="Mesajınız..."></textarea>
                                        <button className="thm-btn bg-cl-1" type="submit">Mesaj Gönder</button>
                                    </form>

                                    {
                                        this.state.mailresult === 1 ? <h3 style={{ color: 'green' }}>Mesajınız başarıyla ulaşmıştır. En kısa sürede dönüş yapılacaktır</h3> : ''
                                    }

                                    {
                                        this.state.mailresult === 2 ? <h3 style={{ color: 'red' }}>Mesaj gönderilemedi. Tekrar deneyiniz</h3> : ''
                                    }


                                </div>
                            </div>

                            <div className="col-md-12" style={{ marginTop: '20px' }} id="google-map-area">
                                <iframe title="ea" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3007.5829378964936!2d29.005436715825404!3d41.07810827929381!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab65d99760197%3A0x3538a9971c719875!2zRXNlbnRlcGUsIEvDtnByw7xsw7wgU2suIE5vOjMsIDM0Mzk0IMWeacWfbGkvxLBzdGFuYnVs!5e0!3m2!1str!2str!4v1623018032486!5m2!1str!2str" width="100%" height="300" style={{ 'border': "0" }} allowFullScreen="no" loading="lazy"></iframe>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
