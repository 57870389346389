import React from 'react'
import { connect } from 'react-redux'
import { AppState } from '../Reducer/ReducerCombiner';
import { ProjectsFullDto } from '../Models/ProjeDto'
import * as ProjeActions from '../Actions/ProjeActions'
import { bindActionCreators } from 'redux';

export interface ProjeProps extends ProjectsFullDto, ProjeActions.ProjectsActionsDeclerations {

}

class projeler extends React.Component {
    render() {
        return (
            <div>
                <section className="breadcrumb-area" style={{ backgroundImage: 'url(images/breadcrumb/projelerbanner.jpg)' }}>
                    <div className="container-fluid text-center">
                        <h1>{ }</h1>
                        <div className="breadcrumb-bottom">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="left pull-left">
                                            <ul>
                                                <li><a href="/">Ana Sayfa</a></li>
                                                <li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                                                <li>Projeler</li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="project-area" className="pro-withtext-area">
                    <div className="container">

                        <div className="row project-content masonary-layout filter-layout">

                            <div className="single-project-item col-md-4 col-sm-4 col-xs-12 filter-item resid commer resid">
                                <div className="img-holder">
                                    <a href="/restoranproje">  <img src="images/project/projebaslik/1.jpg" alt="." /></a>
                                    <div className="overlaystl">
                                        <div className="box">
                                            <div className="content">
                                                <a href="/yikimsokum">
                                                    <h3>Yıkım-Söküm Projeleri</h3>
                                                </a>
                                                <p>Stl İnşaat</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="single-project-item col-md-4 col-sm-4 col-xs-12 filter-item resid commer resid">
                                <div className="img-holder">
                                    <a href="/restoranproje">  <img src="images/project/projebaslik/2.jpg" alt="." /></a>
                                    <div className="overlaystl">
                                        <div className="box">
                                            <div className="content">
                                                <a href="/karotdelme">
                                                    <h3>Karot-Delme-Kesme Projeleri</h3>
                                                </a>
                                                <p>Stl İnşaat</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="single-project-item col-md-4 col-sm-4 col-xs-12 filter-item resid commer resid">
                                <h3>PROJELER</h3>
                                <p><b>STL İnşaat</b> deneyimi, detaylara verilen özel önem ve titiz çalışmalarımız ile uygulanan her projenin zamanında uygun olarak hayata geçmesini sağlıyor. Mükemmeliyetçi STL inşaat yaklaşımı ile uygulanan her proje tüm detayları ile siz değerli müşterilerimizi güven duygusu içinde iyi hissettiriyor.</p>
                            </div>

                        </div>
                        <div className="row project-content masonary-layout filter-layout">

                            <div className="single-project-item col-md-4 col-sm-4 col-xs-12 filter-item resid commer resid">
                                <div className="img-holder">
                                    <a href="/restoranproje">  <img src="images/project/projebaslik/3.jpg" alt="." /></a>
                                    <div className="overlaystl">
                                        <div className="box">
                                            <div className="content">
                                                <a href="/insaatisleri">
                                                    <h3>İnşaat işleri Projeleri</h3>
                                                </a>
                                                <p>Stl İnşaat</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="single-project-item col-md-4 col-sm-4 col-xs-12 filter-item resid commer resid">
                                <div className="img-holder">
                                    <a href="/restoranproje">  <img src="images/project/projebaslik/4.jpg" alt="." /></a>
                                    <div className="overlaystl">
                                        <div className="box">
                                            <div className="content">
                                                <a href="/restoranproje">
                                                    <h3>Restoran Yapım Projeleri</h3>
                                                </a>
                                                <p>Stl İnşaat</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch: any, ownProps: ProjeProps) => {
    return bindActionCreators({
        ...ProjeActions,
    }, dispatch);
};


const mapStateToProps = (state: AppState, props: ProjeProps) => {
    return {
        ...props,
        Projects: state.Projects
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(projeler);