import { Dispatch } from 'redux'
import { Projects } from './../Models/ProjeDto'
import { ProjectsActionType } from './../Models/ActionTypeEnum'


export const getProjects = () => {
    return async (dispatch: Dispatch<any>): Promise<Projects[] | false> => {
        dispatch({ type: ProjectsActionType.AllProjects, data: [] })
        return false;
    };
};


export function selectedProjectss(payload: any) {
    return {
        type: ProjectsActionType.ADD_SELECT,
        payload,
    };

}

export const selectedProjects = (item: Projects) => {
    return async (dispatch: Dispatch<any>): Promise<Projects | false> => {
        dispatch({ type: ProjectsActionType.SelectProjects, data: item })
        return false;
    };
};

export interface ProjectsActionsDeclerations {
    getProjects(): Promise<Projects[] | false>;
    selectedProjects(item: Projects): void;
    selectedProjectss(payload: any): void
}

