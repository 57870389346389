
import { AnyAction } from 'redux';
import { ProjectsFullDto } from './../Models/ProjeDto';
import { ProjectsActionType } from '../Models/ActionTypeEnum'


export default function ProjectReducer(state: any = IntializeData, action: AnyAction) {
    switch (action.type) {
        case ProjectsActionType.AllProjects:
            return { ...state.Projects };
        case ProjectsActionType.ADD_SELECT:
            return { ...state, selectProjects: action.payload };
        default: return state;
    }
}

export const IntializeData: ProjectsFullDto = {

    Projects: [{
        type: 4,
        id: 1,
        projectName: 'Arbys Restoran Yapımı',
        mainImage: '/images/project/restoran/arby/1.jpg',
        images: [
            '/images/project/restoran/arby/1.jpg',
            '/images/project/restoran/arby/2.jpg',
            '/images/project/restoran/arby/3.jpg',
            '/images/project/restoran/arby/4.jpg',
            '/images/project/restoran/arby/5.jpg',
            '/images/project/restoran/arby/6.jpg',
            '/images/project/restoran/arby/7.jpg',
            '/images/project/restoran/arby/8.jpg',
            '/images/project/restoran/arby/9.jpg',
            '/images/project/restoran//arby/10.jpg',
            '/images/project/restoran/arby/11.jpg',]
    }, {
        id: 2,
        type: 4,
        projectName: 'Burger King Restoran Yapımı',
        mainImage: '/images/project/restoran/burgerking/1.jpg',
        images: [
            '/images/project/restoran/burgerking/1.jpg',
            '/images/project/restoran/burgerking/2.jpg',
            '/images/project/restoran/burgerking/3.jpg',
            '/images/project/restoran/burgerking/4.jpg',
            '/images/project/restoran/burgerking/5.jpg',
            '/images/project/restoran/burgerking/6.jpg',
            '/images/project/restoran/burgerking/7.jpg',
            '/images/project/restoran/burgerking/8.jpg',
            '/images/project/restoran/burgerking/9.jpg']
    }, {
        id: 3,
        type: 4,
        projectName: 'Popeyes Restoran Yapımı',
        mainImage: '/images/project/restoran/popeyes/1.jpg',
        images: [
            '/images/project/restoran/popeyes/1.jpg',
            '/images/project/restoran/popeyes/2.jpg',
            '/images/project/restoran/popeyes/3.jpg',
        ]
    }, {
        id: 4,
        type: 4,
        projectName: 'Usta Dönerci Restoran Yapımı',
        mainImage: '/images/project/restoran/ustadonerci/1.jpg',
        images: [
            '/images/project/restoran/ustadonerci/1.jpg',
        ]
    }, {
        id: 5,
        type: 4,
        projectName: 'Usta Pideci Restoran Yapımı',
        mainImage: '/images/project/restoran/ustapideci/1.jpg',
        images: [
            '/images/project/restoran/ustapideci/1.jpg',
            '/images/project/restoran/ustapideci/2.jpg',
            '/images/project/restoran/ustapideci/3.jpeg',
        ]
    }, {
        id: 6,
        type: 4,
        projectName: 'Sbarro Restoran Yapımı',
        mainImage: '/images/project/restoran/sbarro/1.jpeg',
        images: [
            '/images/project/restoran/sbarro/1.jpeg',
            '/images/project/restoran/sbarro/2.jpeg',
            '/images/project/restoran/sbarro/3.jpg',
        ]
    }, {
        id: 7,
        type: 4,
        projectName: 'Usta Pideci Restoran Yapımı',
        mainImage: '/images/project/restoran/ustapideci2/1.jpeg',
        images: [
            '/images/project/restoran/ustapideci2/1.jpeg',
            '/images/project/restoran/ustapideci2/1.jpeg',
        ]
    }, {
        id: 8,
        type: 4,
        projectName: 'Sbarro Restoran Yapımı',
        mainImage: '/images/project/restoran/sbarro2/1.jpg',
        images: [
            '/images/project/restoran/sbarro2/1.jpg',
        ]
    }
    ],
    DelmeProjects: [
        '/images/project/delmeisleri/1.jpeg',
        '/images/project/delmeisleri/2.jpeg',
        '/images/project/delmeisleri/3.jpeg',
        '/images/project/delmeisleri/4.jpeg',
        '/images/project/delmeisleri/5.jpeg',
        '/images/project/delmeisleri/6.jpeg',
       
    ],
    YikimProjects: [
        '/images/project/yikimisleri/1.jpeg',
        '/images/project/yikimisleri/2.jpeg',
        '/images/project/yikimisleri/3.jpeg',
        '/images/project/yikimisleri/4.jpeg',
        '/images/project/yikimisleri/5.jpeg',
        '/images/project/yikimisleri/6.jpeg',
        '/images/project/yikimisleri/7.jpeg',
        '/images/project/yikimisleri/8.jpeg',
        '/images/project/yikimisleri/9.jpeg',
        '/images/project/yikimisleri/10.jpeg',
        '/images/project/yikimisleri/11.jpeg',
        '/images/project/yikimisleri/12.jpeg',
        '/images/project/yikimisleri/13.jpeg',
        '/images/project/yikimisleri/14.jpeg',

    ],
    InsaatProjects: [
        '/images/project/insaatisleri/1.jpeg',
        '/images/project/insaatisleri/2.jpeg',
        '/images/project/insaatisleri/3.jpeg',
        '/images/project/insaatisleri/4.jpeg',
        '/images/project/insaatisleri/5.jpeg',
        '/images/project/insaatisleri/6.jpeg',
        '/images/project/insaatisleri/7.jpeg',
        '/images/project/insaatisleri/8.jpeg',
        '/images/project/insaatisleri/9.jpeg',
        '/images/project/insaatisleri/10.jpeg',
        '/images/project/insaatisleri/11.jpeg',
        '/images/project/insaatisleri/12.jpeg',
        '/images/project/insaatisleri/13.jpeg',
        '/images/project/insaatisleri/14.jpeg',
        '/images/project/insaatisleri/15.jpeg',
        '/images/project/insaatisleri/16.jpeg',
        '/images/project/insaatisleri/17.jpeg'
    ],
    selectProjects: undefined
}