//import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AppState } from '../Reducer/ReducerCombiner';
import { ProjectsFullDto } from '../Models/ProjeDto'
import * as ProjeActions from '../Actions/ProjeActions'
import { bindActionCreators } from 'redux';
import { MainComponent } from './../Models/ProjeDto';


export interface ProjeProps extends ProjectsFullDto, ProjeActions.ProjectsActionsDeclerations {
    data: ProjectsFullDto;
}

class projedetay extends MainComponent<ProjeProps, ProjectsFullDto> {
    constructor(props: any) {
        super(props);
        this.state = { ...props.data }
        if (props.match.params.id) {
             this.index = this.state.Projects.findIndex(a => a.id === Number(props.match.params.id))
        }
        console.log(this.index)

    }
    private isHave = true;
    private index:number=-1;


    render() {

        return (
            <div>
                <section className="breadcrumb-area" style={{ backgroundImage: 'url(/images/breadcrumb/projelerbanner.jpg)' }}>
                    <div className="container-fluid text-center">
                        <h1>{ }</h1>
                        <div className="breadcrumb-bottom">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="left pull-left">
                                            <ul>
                                                <li><a href="/">Ana Sayfa</a></li>
                                                <li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                                                <li>Projeler</li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {this.isHave ?
                    <section id="project-single-area" className="project-single-v1-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                                    <div id="project-single-carousel" className="carousel slide" data-ride="carousel">
                                        <ol className="carousel-indicators">

                                            {this.state.Projects[this.index].images.map((value: any, i: number) => {
                                                return (
                                                    <li key={i} data-target="#project-single-carousel" data-slide-to={i} className={i === 0 ? 'active' : ''}></li>
                                                )
                                            })}
                                        </ol>
                                        <div className="carousel-inner" role="listbox">
                                            {this.state.Projects[this.index].images.map((value: any, i: number) => {
                                                return (
                                                    <div key={i} className={i === 0 ? 'item active' : 'item'}>
                                                        <div className="single-item">
                                                            <div className="img-holder">
                                                                <img style={{ height: '430px', width: '770px' }} src={value} alt="." />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                                    <div className="project-info">
                                        <h3>{this.state.Projects[this.index].projectName}</h3>
                                        <p>{this.state.Projects[this.index].projectName} işi  eksiksiz bir şekilde tamamlanmış ve teslim edilmiştir. </p>

                                    </div>
                                </div>
                            </div>


                        </div>

                    </section>
                    : <div>
                        <section className="working-area">
                            <div className="container">
                                <div className="sec-title text-center">
                                    <h2> Proje bulunamadı</h2>
                                    <p><a href="/projeler">Tüm Projeleri görmek için tıklayınız...</a></p>
                                </div>

                            </div>
                        </section>
                    </div>
                }

            </div>
        )
    }
}


const mapDispatchToProps = (dispatch: any, ownProps: ProjectsFullDto) => {
    return bindActionCreators({
        ...ProjeActions,
    }, dispatch);
};


const mapStateToProps = (state: AppState, props: any) => {
    return {
        ...props,
        data: state.Projects
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(projedetay);































