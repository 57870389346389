import React, { Component } from 'react'

export default class hakkimizda extends Component {
    render() {
        return (
            <div>
                <section className="breadcrumb-area" style={{ backgroundImage: 'url(images/breadcrumb/hakkimizdabanner.jpg)' }}>
                    <div className="container-fluid text-center">
                        <h1>{ }</h1>
                        <div className="breadcrumb-bottom">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="left pull-left">
                                            <ul>
                                                <li><a href="/">Ana Sayfa</a></li>
                                                <li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                                                <li>Hakkımızda</li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="welcome-area">
                    <div className="container clearfix">
                        <div className="sec-title">
                            <h2>STL <span>İnşaat;</span></h2>
                            <span className="decor"></span>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-sm-12 col-xs-12">
                                <div className="text-holder">
                                    <h3>2016 yılında İstanbul'da kurulmuştur.</h3>
                                    <p>Kuruluşumuzdan bugüne kadar kalite çizgisinden ödün vermeyerek uzman kadro ve ekibi ile kırım karot anahtar teslim projelerini eksiksiz olarak teslim etmektedir. Firma olarak önceliğimiz müşteri memnuniyeti kaliteli hizmet sunmaktır.</p>
                                    <p>Firmamız birçok büyük firmaya yapı, tadilat ve yıkım konusunda hizmet vermenin yanında çözüm ortağı olarak destek vermektedir.<br /><br />
                                        Kaliteli hizmet ilkesini benimsemiş genç, dinamik ve profesyonel kadrosunu son teknoloji araçlarla destekleyerek sektörde ön sıralarda yer alma ilkemizi devam ettirmekteyiz, sizlerinde inşaat konusunda sorunlarını çözmek ve iş ortaklığı yapmaktan memnuniyet duyarız.</p>
                                    <div className="bottom">
                                        <div className="button">
                                            <a className="thm-btn bg-cl-1" href="/iletisim">Bize Ulaşın</a>
                                        </div>
                                        <div className="title">
                                            <h3>Mail: <span>info@stlinsaat.com</span></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 col-xs-12">
                                <div className="gallery clearfix">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 col-xs-12">
                                            <div clcclassNamelassNameass="video-gallery">
                                                <img src="/images/about/hakkimizda1.jpg" alt="Awesome Video Gallery" />
                                                <div className="overlay-gallery">
                                                    <div className="icon-holder">
                                                        <div className="icon">
                                                            <a className="video-fancybox" title="Interrio Gallery" href="https://www.youtube.com/watch?v=KssOT2QVg-M"><img src="/images/icon/play-btn.png" alt="Play Button" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12">
                                            <div className="gallery-bg-img">
                                                <img src="/images/about/hakkimizda2.jpg" alt="." />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <section className="brand-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="brand">

                                    <div className="single-item">
                                        <a href="."><img height="50px" width="50px" src="/images/brand/brand1.png" alt="." /></a>
                                    </div>

                                    <div className="single-item">
                                        <a href="."><img height="85" width="85" src="/images/brand/brand2.png" alt="." /></a>
                                    </div>

                                    <div className="single-item">
                                        <a href="."><img height="85" width="85" src="/images/brand/brand3.png" alt="." /></a>
                                    </div>

                                    <div className="single-item">
                                        <a href="."><img height="85" width="85" src="/images/brand/brand4.png" alt="." /></a>
                                    </div>
                            
                                    <div className="single-item">
                                        <a href="."><img height="50px" width="50px" src="/images/brand/brand1.png" alt="." /></a>
                                    </div>

                                    <div className="single-item">
                                        <a href="."><img height="85" width="85" src="/images/brand/brand2.png" alt="." /></a>
                                    </div>

                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="working-area">
                    <div className="container">
                        <div className="sec-title text-center">
                            <h2>FAALİYET  alanlarımız</h2>
                            <p>STL İnşaat olarak; 3 faaliyet alanında profesyonel hizmet vermekteyiz</p>
                        </div>

                        <div className="row">
                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <div className="single-working-item">
                                    <div className="icon-box">
                                        <div className="icon">
                                            <span className="flaticon-people"></span>
                                        </div>
                                        <div className="count">
                                            <h3>1</h3>
                                        </div>
                                    </div>
                                    <div className="text-box text-center">
                                        <h3>Yıkım-Söküm İşleri</h3>
                                        <p>Tüm yıkım işlemleri yıkım öncesi geniş çaplı incelendikten sonra, iş güvenliği ve emniyet tedbirleri alınır. Şartlara göre elle veya makina ile
                                            binaların durumu göz bulundurulur ve gerekli tüm emniyet tedbirleri alınarak yıkım ve sulama faliyetleri yapılır.</p>
                                    </div>
                                </div>
                            </div>
                                        <div className="col-md-4 col-sm-6 col-xs-12">
                                <div className="single-working-item">
                                    <div className="icon-box">
                                        <div className="icon">
                                            <span className="flaticon-people"></span>
                                        </div>
                                        <div className="count">
                                            <h3>3</h3>
                                        </div>
                                    </div>
                                    <div className="text-box text-center">
                                        <h3>Karot-Delme-Kesme İşleri</h3>
                                        <p>Karot delme ve kesme işlemleri, yapılacak olan işin niteliğine göre uygun makinalar ve profesyonel ekibimizle yapılmaktadır.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12">
                                <div className="single-working-item">
                                    <div className="icon-box">
                                        <div className="icon">
                                            <span className="flaticon-people"></span>
                                        </div>
                                        <div className="count">
                                            <h3>2</h3>
                                        </div>
                                    </div>
                                    <div className="text-box text-center">
                                        <h3>İnşaat  İşleri</h3>
                                        <p>SRT inşaat olarak, tüm yapı işlemlerimizde alanında uzman mimar personelimizle gerçekleştirmekte ve bu alanda ülkemizde büyük firmalar ile çalışmanın gururunu yaşamaktayız.</p>
                                    </div>
                                </div>
                            </div>
                
                          
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}
