import React from 'react';

import Home from './page/index'
import Hakkimizda from './page/hakkimizda'
import Iletisim from './page/iletisim'
import Footer from './page/footer'
import Projeler from './page/projeler'
import Restora from './page/restora'
import InsaatIsleri from './page/insaatisleri'
import KarotDelme from './page/karotdelme'
import YıkımSokum from './page/yikimsokum'

import ProjeDetay from './page/projedetay'

import './App.css'
//import Email from './page/email'

import Nav from './page/nav'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";


function App() {


  return (
    <div>
      <Nav id={window.location.pathname} />
      <Router>
        <Switch>
          <Route component={Home} exact path="/" />
          <Route component={Projeler} exact path="/projeler" />
          <Route component={Hakkimizda} exact path="/hakkimizda" />
          <Route component={Iletisim} exact path="/iletisim" />
          <Route component={InsaatIsleri} exact path="/insaatisleri" />
          <Route component={KarotDelme} exact path="/karotdelme" />
          <Route component={YıkımSokum} exact path="/yikimsokum" />

          {/* <Route path="/restoranproje" render={(props) => <Restoranproje projeDegistir={(a) => setCount(a)} projeId={projeId} {...props} />} /> */}
          <Route component={Restora} exact path="/restoranproje" />
          <Route path="/projedetay/:id" render={(props) => <ProjeDetay {...props} />} />

          {/* <Route
          path="/projedetay/:id"
          render={({ id }) => <ProjeDetay match={id} />}
        />  */}

          {/*  <Route component={Email} exact path="/email" /> */}

        </Switch>
      </Router>
      {/* <button onClick={() => setCount('butondan geldi')}>aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa</button> */}
      <Footer />
    </div>
  );
}



export default App;
