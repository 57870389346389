import React, { Component } from 'react'

export default class index extends Component {
    render() {
        return (
            <div>



                <section className="rev_slider_wrapper">
                    <div id="slider1" className="rev_slider" data-version="5.0">
                        <ul>
                            <li data-transition="slidingoverlayleft">
                                <img src="/images/slides/1.jpg" alt="" width="1920" height="800" data-bgposition="top center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="1" />

                                <div className="tp-caption  tp-resizeme"
                                    data-x="left" data-hoffset="0"
                                    data-y="top" data-voffset="205"
                                    data-transform_idle="o:1;"
                                    data-transform_in="x:[-175%];y:0px;z:0;rX:0;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0.01;s:3000;e:Power3.easeOut;"
                                    data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
                                    data-mask_in="x:[100%];y:0;s:inherit;e:inherit;"
                                    data-splitin="none"
                                    data-splitout="none"
                                    data-start="700">
                                   <div className="slide-content-box">
                  <h1>Yapı İŞLERİNDE<br />ÇÖZÜM ORTAĞINIZ<span></span></h1>
                  <h3> Sayısız proje deneyimi ve güçlü kadrosuyla<span></span> </h3>
                </div> 
                                </div>
                                <div className="tp-caption tp-resizeme"
                                    data-x="left" data-hoffset="0"
                                    data-y="top" data-voffset="475"
                                    data-transform_idle="o:1;"
                                    data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                                    data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
                                    data-splitin="none"
                                    data-splitout="none"
                                    data-responsive_offset="on"
                                    data-start="2300">
                                    {/* <div className="slide-content-box">
                  <div className="button">
                    <a className="thm-btn bg-cl-1" href="/">Our Services</a>
                  </div>
                </div> */}
                                </div>
                                <div className="tp-caption tp-resizeme"
                                    data-x="left" data-hoffset="199"
                                    data-y="top" data-voffset="475"
                                    data-transform_idle="o:1;"
                                    data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                                    data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
                                    data-splitin="none"
                                    data-splitout="none"
                                    data-responsive_offset="on"
                                    data-start="2600">
                                    {/* <div className="slide-content-box">
                    <div className="button">
                      <a className="thm-btn bdr" href="/">Latest Projects</a>
                    </div>
                  </div> */}
                                </div>
                            </li>
                            <li data-transition="slidingoverlayleft">
                                <img src="/images/slides/2.jpg" alt="" width="1920" height="800" data-bgposition="top center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="1" />

                                <div className="tp-caption tp-resizeme"
                                    data-x="right" data-hoffset="0"
                                    data-y="top" data-voffset="205"
                                    data-transform_idle="o:1;"
                                    data-transform_in="x:[-175%];y:0px;z:0;rX:0;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0.01;s:3000;e:Power3.easeOut;"
                                    data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
                                    data-mask_in="x:[100%];y:0;s:inherit;e:inherit;"
                                    data-splitin="none"
                                    data-splitout="none"
                                    data-start="500">
                                                      <div className="slide-content-box">
                  <h1>Yapı İŞLERİNDE<br />ÇÖZÜM ORTAĞINIZ<span></span></h1>
                  <h3> Sayısız proje deneyimi ve güçlü kadrosuyla<span></span> </h3>
                </div> 
                                </div>
                                <div className="tp-caption tp-resizeme"
                                    data-x="right" data-hoffset="209"
                                    data-y="top" data-voffset="475"
                                    data-transform_idle="o:1;"
                                    data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                                    data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
                                    data-splitin="none"
                                    data-splitout="none"
                                    data-responsive_offset="on"
                                    data-start="2300">
                                    {/* <div className="slide-content-box">
                  <div className="button">
                    <a className="thm-btn bdr" href="/">Latest Projects</a>
                  </div>
                </div> */}
                                </div>
                                <div className="tp-caption tp-resizeme"
                                    data-x="right" data-hoffset="436"
                                    data-y="top" data-voffset="475"
                                    data-transform_idle="o:1;"
                                    data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                                    data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
                                    data-splitin="none"
                                    data-splitout="none"
                                    data-responsive_offset="on"
                                    data-start="2600">
                                    {/* <div className="slide-content-box">
                  <div className="button">
                    <a className="thm-btn bg-cl-1" href="/">Our Services</a>
                  </div>
                </div> */}
                                </div>
                            </li>
                            <li data-transition="slidingoverlayleft">
                                <img src="/images/slides/3.jpg" alt="" width="1920" height="800" data-bgposition="top center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="1" />

                                <div className="tp-caption  tp-resizeme"
                                    data-x="left" data-hoffset="0"
                                    data-y="top" data-voffset="205"
                                    data-transform_idle="o:1;"
                                    data-transform_in="x:[-175%];y:0px;z:0;rX:0;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0.01;s:3000;e:Power3.easeOut;"
                                    data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
                                    data-mask_in="x:[100%];y:0;s:inherit;e:inherit;"
                                    data-splitin="none"
                                    data-splitout="none"
                                    data-start="700">
                                                      <div className="slide-content-box">
                  <h1>Yapı İŞLERİNDE<br />ÇÖZÜM ORTAĞINIZ<span></span></h1>
                  <h3> Sayısız proje deneyimi ve güçlü kadrosuyla<span></span> </h3>
                </div> 
                                </div>
                                <div className="tp-caption tp-resizeme"
                                    data-x="left" data-hoffset="0"
                                    data-y="top" data-voffset="475"
                                    data-transform_idle="o:1;"
                                    data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                                    data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
                                    data-splitin="none"
                                    data-splitout="none"
                                    data-responsive_offset="on"
                                    data-start="2300">
                                    {/* <div className="slide-content-box">
                  <div className="button">
                    <a className="thm-btn bg-cl-1" href="/">Our Services</a>
                  </div>
                </div> */}
                                </div>
                                <div className="tp-caption tp-resizeme"
                                    data-x="left" data-hoffset="199"
                                    data-y="top" data-voffset="475"
                                    data-transform_idle="o:1;"
                                    data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                                    data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
                                    data-splitin="none"
                                    data-splitout="none"
                                    data-responsive_offset="on"
                                    data-start="2600">
                                    {/* <div className="slide-content-box">
                  <div className="button">
                    <a className="thm-btn bdr" href="/">Latest Projects</a>
                  </div>
                </div> */}
                                </div>
                            </li>

                        </ul>
                    </div>
                </section>

            </div>
        )
    }
}
