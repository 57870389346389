import React from 'react'

export interface Projects {
    id:number;
    projectName:string;
    mainImage?:string;
    type: number,
    images:string[]
}
export interface ProjectsFullDto {

   Projects : Projects[],
   InsaatProjects:string[],
   DelmeProjects:string[],
  YikimProjects:string[],

   selectProjects?:Projects
}

export interface ProjeReqModel {
    id:string;
}


export class MainComponent<P = {}, S = {}> extends React.Component<P, S> {
}

