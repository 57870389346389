import { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
//import { useHistory } from 'react-router-dom';
//import { selectedProjectss } from '../Actions/ProjeActions';
import { AppState } from '../Reducer/ReducerCombiner';
//import { Projects } from './../Models/ProjeDto'

const InsaatIsleri: FunctionComponent<{ initial?: number }> = () => {
    const getStateValue = useSelector((state: AppState) => state.Projects);
    //   const dispatch = useDispatch();
    //    const history = useHistory();
    //
    useEffect(() => {
        //  console.log('getStateValue', getStateValue.Projects)
    }, [])

    // const selectedProject = (value: Projects) => {
    //     dispatch(selectedProjectss(value))
    //     history.push("/projedetay/10");
    // }

    return <>
        <div>
            <section className="breadcrumb-area" style={{ backgroundImage: 'url(/images/breadcrumb/projelerbanner.jpg)' }}>
                <div className="container-fluid text-center">
                    <h1>{ }</h1>
                    <div className="breadcrumb-bottom">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="left pull-left">
                                        <ul>
                                            <li><a href="/">Ana Sayfa</a></li>
                                            <li><a href="/projeler"><i className="fa fa-angle-right" aria-hidden="true"></i>Projeler</a></li>
                                            <li><i className="fa fa-angle-right" aria-hidden="true"></i>İnşaat Projeleri</li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="project-area" className="pro-withtext-area">
                <div className="container">
                    <div className="row project-content masonary-layout filter-layout">
                        {getStateValue.InsaatProjects.map((value: any, i: number) => {
                           
                                return (
                                    <div key={i} className="single-project-item col-md-4 col-sm-4 col-xs-12 filter-item resid commer corp">
                                        <div className="img-holder">
                                            <img src={value} style={{ height: '270px', width: '370px' }} alt="Stl İnşaat işleri" />
                                            <div className="overlay">
                                                <div className="box">
                                                    <div className="content">
                                                        <div className="icon-holder">
                                                            <a href={value} data-rel="prettyPhoto" title="Stl İnşaat işleri"><span className="flaticon-cross"></span></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                           <h3>{value.projectName}</h3>
                                            <p>Stl İnşaat</p>
                                        </div>
                                    </div>

                                )
                           
                        })
                        }
                        {
                            getStateValue.Projects.length === 0 ? <h1>Proje Bulunamadı</h1> : ''
                        }
                    </div>
                </div>
            </section>
        </div>
    </>
}

export default InsaatIsleri;